import React from "react";
import { graphql } from "gatsby";

import policyDocument from "../assets/files/BDAR_aprasas_2020-09-21.pdf";

import SEO from "../components/seo";

import styles from "./privacy-policy.module.scss";

export const query = graphql`
  query PrivacyPolicyQuery($locale: String!) {
    translations(locale: $locale) {
      ...LayoutTranslations
    }
  }
`;

const PrivacyPolicyPage = () => {
  return (
    <section className={styles.page}>
      <div className="bp-container">
        <SEO
          title="Privatumo politika"
          description="
            Privatumo politikoje, portalo lankytojams, pateikiame informaciją apie tai, kaip Visuomeninė
            organizacija „Baltosios pirštinės“ renka ir tvarko Jūsų asmens duomenis, kuriuos Jūs pateikiate
            apsilankę Portale, susisiekę su mumis el. paštu ar telefonu, ar apsilankę Asociacijos
            buveinėje.
          "
        />

        <h1 className="bp-center">Privatumo politika</h1>
        <h2>Ką reiškia ši Privatumo politika?</h2>
        <p>
          Šioje Privatumo politikoje Jums, portalo 
          <a href="/" target="_blank" rel="nofollow" className="bp-link">
            https://www.baltosiospirstines.lt/
          </a>{" "}
          (toliau - Portalas) lankytojams, pateikiame informaciją apie tai, kaip Visuomeninė
          organizacija „Baltosios pirštinės“ (juridinio asmens kodas: 303473851, registruotos
          buveinės adresas: Taikos g. 14-63, Utena, Lietuvos Respublika, buveinės adresas: Gedimino
          pr. 1 („NVO Avilys“), Vilnius, Lietuvos Respublika) (toliau – Asociacija) tvarko Jūsų
          asmens duomenis, kuriuos Jūs pateikiate apsilankę Portale, susisiekę su mumis el. paštu ar
          telefonu, ar apsilankę Asociacijos buveinėje.
        </p>
        <p>
          Šioje Privatumo politikoje vartojamos sąvokos suprantamos taip, kaip jos apibrėžtos{" "}
          <a
            href={policyDocument}
            download
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link"
          >
            Asociacijos asmens duomenų tvarkymo tvarkos apraše
          </a>
          .
        </p>
        <h2>Kokius Jūsų asmens duomenis mes renkame?</h2>
        <p>
          Jums apsilankius Portale, susisiekus su mumis el. paštu ar telefonu ar apsilankius
          Asociacijos buveinės adresu, galime rinkti Jūsų asmens duomenis:
        </p>
        <ul className="bp-styled-list">
          <li>
            vidaus administravimo tikslu tvarkomi šie duomenys: duomenų subjekto vardas, pavardė,
            gyvenamosios vietos adresas, gimimo data, telefono numeris, elektroninio pašto adresas.
            Asmens duomenys tvarkomi narystės, kadencijos, darbo santykių laikotarpiu ir jiems
            pasibaigus;
          </li>
          <li>
            renginių organizavimo tikslais tvarkomi šie duomenys: dalyvio vardas, pavardė,
            gyvenamoji savivaldybė, telefono numeris, elektroninio pašto adresas, gimimo data,
            maitinimosi poreikiai. Taip pat dalyvio vieno iš tėvų/globėjų vardas, pavardė, telefono
            numeris, elektroninio pašto adresas, jeigu dalyvis yra nepilnametis asmuo iki 18 metų.
            Asmens duomenys tvarkomi ne ilgiau nei du metus po renginio, išskyrus atvejus, kai
            galimų būsimų renginių organizavimui gali būti reikalingi asmens duomenys, kurie
            tvarkomi neribotą laiką, ir kitus LR teisės aktuose numatytus atvejus;
          </li>
          <li>
            „Baltųjų pirštinių“ valdymo organų veiklos organizavimo tikslu tvarkomi šie duomenys:
            duomenų subjekto vardas, pavardė, asmens kodas, gyvenamosios vietos adresas, telefono
            numeris, elektroninio pašto adresas. Asmens duomenys tvarkomi valdymo organo kadencijos
            laikotarpiu;
          </li>
          <li>
            „Baltųjų pirštinių“ biuro veiklos organizavimo tikslu tvarkomi šie duomenys: duomenų
            subjekto vardas, pavardė, gyvenamosios vietos adresas, gimimo data, telefono numeris,
            elektroninio pašto adresas. Asmens duomenys tvarkomi darbo ar savanorystės biure
            laikotarpiu;
          </li>
          <li>
            „Baltųjų pirštinių“ regionų ir savivaldybių koordinatorių, stebėtojų veiklos
            organizavimo tikslu tvarkomi šie duomenys: duomenų subjekto vardas, pavardė, asmens
            kodas, gimimo data, gyvenamosios vietos adresas, telefono numeris, elektroninio pašto
            adresas ir kita duomenų subjekto iniciatyva pateikta informacija. Asmens duomenys
            tvarkomi jų veiklos laikotarpiu ir penkerius metus po veiklos laikotarpio, išskyrus
            duomenų subjekto asmens kodą, kuris tvarkomas iki jo pateikimo Vyriausiajai rinkimų
            komisijai ir patvirtinimo dėl tapimo stebėtoju gavimo;
          </li>
          <li>
            archyvavimo tikslu tvarkomi šie duomenys: duomenų subjekto vardas, pavardė, telefono
            numeris, elektroninio pašto adresas, pareigos, pareigų vykdymo laikotarpis. Asmens
            duomenys tvarkomi 10 metų laikotarpiui, išskyrus Baltosios pirštinės veiklos dokumentus,
            įrodančius veiklos tęstinumą, kurie yra tvarkomi neribotą laikotarpį, nebent teisės
            aktai nurodo kitaip;
          </li>
          <li>
            kandidatų į „Baltųjų pirštinių“ organus ir biuro pozicijas administravimo tikslu
            tvarkomi šie duomenys: duomenų subjekto vardas, pavardė, motyvacinis laiškas, gyvenimo
            aprašymas ir juose pateikti duomenys (jeigu nurodyta: nuotrauka, telefono numeris,
            elektroninio pašto adresas, esama ir buvusios darbovietės, gimimo data, esama ir
            buvusios studijų institucijos, pareigos ir kita pateikta informacija). Asmens duomenys
            tvarkomi ne ilgiau nei mėnesį po rinkimų ar atrankos į atitinkamą poziciją;
          </li>
          <li>
            vidinės komunikacijos su esamais ir buvusiais „Baltųjų pirštinių“ valdymo organų
            nariais, biuro nariais, regionų ir savivaldybių koordinatoriais, stebėtojais tikslu
            tvarkomi šie duomenys: duomenų subjekto vardas, pavardė, telefono numeris, elektroninio
            pašto adresas. Asmens duomenys tvarkomi neribotą laiką, nebent teisės aktai numato
            kitaip;
          </li>
          <li>
            išorinės komunikacijos tikslu tvarkomi šie duomenys: duomenų subjekto vardas, pavardė,
            elektroninio pašto adresas, nuotrauka, pareigos. Asmens duomenys tvarkomi iki kadencijos
            arba darbo sutarties pabaigos;
          </li>
          <li>
            sutartinių įsipareigojimų vykdymo tikslu tvarkomi šie duomenys: duomenų subjekto vardas,
            pavardė, asmens kodas, adresas, elektroninio pašto adresas, telefono numeris,
            individualios veiklos pažymos numeris, banko sąskaitos numeris. Asmens duomenys tvarkomi
            iki sutartinių įsipareigojimų įgyvendinimo;
          </li>
          <li>
            finansinių atsiskaitymų tikslu tvarkomi šie duomenys: duomenų subjekto vardas, pavardė,
            gimimo data, gyvenamosios vietos adresas, telefono numeris, elektroninio pašto adresas,
            pareigos, banko sąskaitos numeris. Asmens duomenys tvarkomi iki savanoriškos veiklos
            arba darbo sutarties galiojimo pabaigos ar kalendorinių metų pabaigos;
          </li>
          <li>
            asmenų skundų, prašymų ir pareiškimų nagrinėjimo tikslu tvarkomi šie duomenys: duomenų
            subjekto vardas, pavardė, asmens kodas, adresas, telefono numeris, elekroninio pašto
            adresas, skundo prašymo ar pareiškimo data ir numeris „Baltųjų pirštinių“ gautų
            dokumentų registre, skunde, prašyme ar pareiškime nurodyta informacija (įskaitant ir
            specialiųjų kategorijų asmens duomenis), skundo, prašymo ar pareiškimo nagrinėjimo
            rezultatas, skundo , prašymo ar pareiškimo nagrinėjimo metu gauta informacija, „Baltųjų
            pirštinių“ atsakymo į skundus, prašymus ar pareiškimus data ir numeris „Baltųjų
            pirštinių“ siunčiamų dokumentų registre. Asmens duomenys tvarkomi vienerius metus po
            skundo, prašymo ar pareiškimo nagrinėjimo pabaigos, nebent teisės aktai numato kitaip.
          </li>
        </ul>
        <h2>Kaip gauname Jūsų asmens duomenis?</h2>
        <p>Jūsų asmens duomenis galime gauti:</p>
        <ul className="bp-styled-list">
          <li>
            iš Jūsų, kai pateikiate mums su mumis bendraudami ar vykdydami kitą teisėtą veiklą savo
            ar kito asmens vardu;
          </li>
          <li>
            iš asmens, kuris su mumis bendradarbiauja ar vykdo kitą teisėtą veiklą Jūsų vardu.
          </li>
        </ul>
        <p>
          Asmuo, bet kokiu būdu teikiantis mums kito asmens duomenis, yra atsakingas už tokių
          duomenų teisingumą ir teisėtumą bei teisės aktų nustatyta tvarka privalo informuoti
          asmenis, kurių asmens duomenis teikia, apie jų asmens duomenų perdavimą mums, įskaitant
          duomenų perdavimo tikslus.
        </p>
        <h2>Kaip tvarkomi Jūsų asmens duomenys?</h2>
        <p>
          Jūsų asmens duomenys bus tvarkomi laikantis 2016 m. balandžio 27 d. Europos Parlamento ir
          Tarybos reglamento (ES) 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir
          dėl laisvo tokių duomenų judėjimo ir kuriuo panaikinama Direktyva 94/46/EB (Bendrasis
          duomenų apsaugos reglamentas), Lietuvos Respublikos asmens duomenų teisinės apsaugos
          įstatymo ir kitų teisės aktų nustatytų reikalavimų. Jūsų asmens duomenys tvarkomi
          atsakingai ir saugiai, užtikrinant teisėtumo sąžiningumo, skaidrumo, tikslo apribojimo,
          duomenų kiekio mažinimo, tikslumo, saugojimo trukmės apribojimo, vientisumo ir
          konfidencialumo principų įgyvendinimą. Įstaiga, tiek nustatydama asmens duomenų tvarkymo
          priemones, tiek paties duomenų tvarkymo metu, įgyvendina tinkamas teisės aktuose
          nustatytas duomenų apsaugos technines ir organizacines priemones, skirtas apsaugoti
          tvarkomus asmens duomenis nuo atsitiktinio ar neteisėto sunaikinimo, sugadinimo,
          pakeitimo, praradimo, atskleidimo, taip pat nuo bet kokio kito neteisėto tvarkymo.
        </p>
        <h2>
          Kaip naudojami Jūsų asmens duomenys, kuriuos mums pateikiate per socialinės žiniasklaidos
          priemones?
        </h2>
        <p>
          Visą informaciją, kurią mums pateikiate socialinės žiniasklaidos priemonėmis (įskaitant
          pranešimus, laukelių „Like“ ir „Follow“ naudojimą, bei kitą komunikaciją), kontroliuoja
          socialinio tinklo valdytojas.
        </p>
        <p>Šiuo metu turime šias paskyras socialiniuose tinkluose:</p>
        <ul className="bp-styled-list">
          <li>
            „Facebook“ –{" "}
            <a
              href="https://www.facebook.com/BaltosiosPirstines/"
              className="bp-link"
              rel="noreferrer noopener nofollow"
            >
              @BaltosiosPirstines
            </a>
            , kurio privatumo politika yra pateikiama adresu{" "}
            <a
              href="https://www.facebook.com/privacy/explanation"
              className="bp-link"
              rel="noreferrer noopener nofollow"
            >
              https://www.facebook.com/privacy/explanation
            </a>
            ;
          </li>
          <li>
            „Instagram“ –{" "}
            <a
              href="https://www.instagram.com/baltosiospirstines/"
              className="bp-link"
              rel="noreferrer noopener nofollow"
            >
              @baltosiospirstines
            </a>
            , kurio privatumo politika yra pateikiama adresu{" "}
            <a
              href="https://help.instagram.com/402411646841720"
              className="bp-link"
              rel="noreferrer noopener nofollow"
            >
              https://help.instagram.com/402411646841720
            </a>
            .
          </li>
        </ul>
        <p>
          Rekomenduojame perskaityti socialinių tinklų privatumo politikas ir tiesiogiai susisiekti
          su paslaugų teikėju, jei Jums kyla bet kokių klausimų dėl to, kaip jie naudoja Jūsų asmens
          duomenis.
        </p>
        <h2>Kam galime atskleisti Jūsų asmens duomenis?</h2>
        <p>
          Mes galime atskleisti Jūsų asmens duomenis asmens duomenų tvarkytojams, kurie teikia mums
          paslaugas (atlieka darbus), t.y. informacinių technologijų paslaugų teikėjai, mokėjimo
          paslaugų teikėjai, buhalterinių paslaugų teikėjai, auditoriai, teisininkai, konsultantai,
          ir tvarko Jūsų duomenis Asociacijos, kaip duomenų valdytojo, vardu.
        </p>
        <p>
          Duomenų tvarkytojai turi teisę tvarkyti asmens duomenis tik pagal mūsų nurodymus ir tik ta
          apimtimi, ir tiek, kiek tai yra būtina. Pasitelkdami duomenų tvarkytojus, imamės visų
          reikiamų priemonių, siekdami užtikrinti, kad ir mūsų duomenų tvarkytojai būtų įgyvendinę
          tinkamas organizacines ir technines asmens duomenų saugumą užtikrinančias priemones bei
          išlaikytų asmens duomenų slaptumą.
        </p>
        <p>
          Jūsų duomenys gali būti perduodami valstybės įstaigoms ir institucijoms, kitiems asmenims,
          vykdantiems įstatymų jiems pavestas funkcijas.
        </p>
        <h2>Kokios yra Jūsų teisės?</h2>
        <p>Jūs turite teisę:</p>
        <ul className="bp-styled-list">
          <li>
            pateikti rašytinį prašymą mums suteikti Jums informaciją, ar su Jumis susiję duomenys
            yra tvarkomi, o jei tokie duomenys yra tvarkomi, turite teisę susipažinti su savo asmens
            duomenimis ir šia informacija: duomenų tvarkymo tikslai; atitinkamų asmens duomenų
            kategorijos; duomenų gavėjai arba duomenų gavėjų kategorijos, kuriems buvo arba bus
            atskleisti Jūsų asmens duomenys; kai įmanoma, numatomas asmens duomenų saugojimo
            laikotarpis arba, jei neįmanoma, kriterijai, taikomi tam laikotarpiui nustatyti;
          </li>
          <li>
            reikalauti ištaisyti netikslius ar papildyti neišsamius su Jumis susijusius asmens
            duomenis;
          </li>
          <li>
            reikalauti, kad mes ištrintumėme Jūsų asmens duomenis. Nepaisant to, ši teisė nėra
            absoliuti ir ją galima pagrįsti, kai yra bent viena iš šių priežasčių: asmens duomenys
            nebėra reikalingi, kad būtų pasiekti tikslai, kuriais jie buvo renkami arba kitaip
            tvarkomi; Jūs nusprendėte atšaukti savo sutikimą tvarkyti Jūsų asmens duomenis, kai Jūsų
            asmens duomenys yra tvarkomi išimtinai sutikimo pagrindu ir nėra jokio kito pagrindo
            tvarkyti Jūsų asmens duomenis; Jūsų asmens duomenys buvo tvarkomi neteisėtai; Jūsų
            duomenys turi būti ištrinti, kai mums yra nustatoma prievolė tą padaryti;
          </li>
          <li>
            reikalauti, kad būtų apribotas Jūsų asmens duomenų tvarkymas, kai taikomas vienas iš šių
            atvejų: Jūs užginčijate duomenų tikslumą tokiam laikotarpiui, per kurį mes galime
            patikrinti Jūsų asmens duomenų tikslumą; Jūsų asmens duomenų tvarkymas yra neteisėtas ir
            Jūs nesutinkate, kad Jūsų duomenys būtų ištrinti, ir vietoj to prašote apriboti jų
            naudojimą; mums nebereikia Jūsų duomenų duomenų tvarkymo tikslais, tačiau jų reikia
            Jums, siekiant pareikšti, vykdyti arba apginti teisinius reikalavimus. Kai Jūsų asmens
            duomenų tvarkymas yra apribotas, tokius Jūsų duomenis mes galime tvarkyti, išskyrus
            saugojimą, tik gavus Jūsų sutikimą arba siekiant pareikšti, vykdyti arba apginti
            teisinius reikalavimus ir (arba) apsaugoti Jūsų ar kito asmens teises, arba dėl viešojo
            intereso priežasčių;
          </li>
          <li>
            atšaukti sutikimą tvarkyti Jūsų asmens duomenis, kai asmens duomenys yra tvarkomi
            išimtinai Jūsų sutikimo pagrindu;
          </li>
          <li>
            prašyti, kad Jūsų tvarkomus asmens duomenis persiųstumėme kitam duomenų valdytojui, kai
            tai techniškai yra įmanoma;
          </li>
          <li>
            pateikti mums arba Valstybinei duomenų apsaugos inspekcijai skundą, jei manote, kad
            Jūsų, kaip asmens duomenų subjekto, teisės yra ir (ar) gali būti pažeistos.
          </li>
        </ul>
        <h2>Kaip galite įgyvendinti savo teises?</h2>
        <p>
          Siekdami įgyvendinti savo teises, prašymus, skundus ar reikalavimus pateikite mums raštu:
        </p>
        <ul className="bp-styled-list">
          <li>
            elektroniniu paštu{" "}
            <a href="mailto:info@baltosiospirstines.lt" className="bp-link">
              info@baltosiospirstines.lt
            </a>
            ;
          </li>
          <li>
            siunčiant paštu adresu Gedimino pr. 1 („NVO Avilys“), LT-01103 Vilnius, Lietuvos
            Respublika;
          </li>
          <li>
            atvykus į Asociacijos buveinę, adresu Gedimino pr. 1 („NVO Avilys“), LT-01103 Vilnius,
            Lietuvos Respublika.
          </li>
        </ul>
        <p>
          Į gautus prašymus, skundus ar reikalavimus Jums atsakysime raštu teisės aktų nustatyta
          tvarka ir terminais ir stengiamės pateikti Jums informaciją kiek įmanoma greičiau, bet ne
          vėliau kaip per 30 dienų nuo Jūsų prašymo gavimo. Išimtiniais atvejais, atsakymo
          pateikimas gali būti atidėtas iki 60 dienų.
        </p>
        <p>
          Jeigu, gavus prašymą, skundą ar reikalavimą, mums kils įtarimų dėl besikreipiančiojo
          asmens tapatybės, mes turime teisę paprašyti besikreipiančiojo asmens tapatybės dokumento
          ir (ar) teisės aktų nustatyta tvarka ir (ar) elektroninių ryšių priemonėmis, kurios
          leidžia tinkamai identifikuoti asmenį, patvirtinti savo asmens tapatybę.
        </p>
        <p>
          Jei mes nesugebėsime suteikti Jums reikiamos informacijos ir (ar) turėsite pretenzijų dėl
          to, kaip yra tvarkomi Jūsų asmens duomenys, Jūs turite teisę kreiptis į Valstybinę duomenų
          apsaugos inspekciją, pateikdami skundą.
        </p>
        <h2>Kaip informuosime apie Privatumo politikos pakeitimus?</h2>
        <p>
          Mes galime bet kada atnaujinti ar pakeisti šią Privatumo politiką. Tokia atnaujinta ar
          pakeista Privatumo politika įsigalios nuo jos paskelbimo Portale dienos.
        </p>
        <p>
          Atnaujinę Privatumo politiką, informuosime Jus apie, mūsų nuomone, esminius pakeitimus,
          paskelbdami juos Portale. Apačioje nurodoma „Atnaujinimo data“, kuri leidžia Jums
          sužinoti, kada Privatumo politika atnaujinta paskutinį kartą.
        </p>
        <h2>Kaip su mumis susisiekti?</h2>
        <p>
          Jei turite kokių klausimų, susijusių su šios Privatumo politikos sąlygomis, maloniai
          prašome su mumis susisiekti žemiau nurodytais rekvizitais – skambinant nurodytu telefonu
          ar siunčiant paklausimą el. paštu arba registruotu laišku:
        </p>
        <address className={styles.address}>
          <p>Visuomeninė organizacija „Baltosios pirštinės“</p>
          <p>Juridinio asmens kodas: 303473851</p>
          <p>Adresas: Gedimino pr. 1 („NVO Avilys“), LT-01103 Vilnius, Lietuvos Respublika</p>
          <p>
            Tel.: 
            <a href="tel:+37063313177" className="bp-link">
              +370 633 13177
            </a>
          </p>
          <p>
            El. paštas: 
            <a href="mailto:info@baltosiospirstines.lt" className="bp-link">
              info@baltosiospirstines.lt
            </a>
          </p>
        </address>
        <p>
          Atnaujinimo data: <time dateTime="2020-09-21">2020-09-21</time>.
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicyPage;
